import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import color from '../../constants/colors';
import { H4 } from '../../constants/fonts';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Button from '../../components/common/components/Button';
import { aboutBg } from '../../assets/images';
import ContentContainer from '../../components/common/components/ContentContainer';

const Wrapper = styled.div`
  position: relative;
  padding: 15vh 0;
  &::before {
    background-image: url(${aboutBg});
    background-repeat: no-repeat;
    background-size: 68%;
    background-position: top right;
    position: absolute;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
  }
`;

const AboutIntro = styled(H4)`
  color: ${color.black};
  font-weight: 700;
`;

const About = ({ data }) => {
  const about = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <>
        <SEO title="O nas" />
        <Wrapper>
          <Container>
            <Row align="start">
              <Col lg={6} sm={12}>
                {about.map(edge => (
                  <ContentContainer key={edge.node.frontmatter.path}>
                    <AboutIntro>{edge.node.frontmatter.intro}</AboutIntro>
                    <H4>{edge.node.frontmatter.established}</H4>
                    <p>{edge.node.frontmatter.description}</p>
                    <Button link="/contact" text="Kontakt" />
                  </ContentContainer>
                ))}
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/about/" } }) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            intro
            established
            description
          }
        }
      }
    }
  }
`;

export default About;
